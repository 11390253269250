<template>
  <div class="main">
        <transition name="fade">
      <terms-business-investor-modal v-show="modalTermsBusinessInvestor" @closeModal="modalTermsBusinessInvestor = false"/>
    </transition>

    <div v-if="isBusinessPartnerAccess" class="flex flex-col justify-center bg-white shadow-md">
      <div class="container flex justify-center mb-4 h-16">
        <nav-bar :isBusinessPartnerAccess="isBusinessPartnerAccess" />
      </div>
    </div>

    <div v-if="showSpinner" class="flex flex-col justify-center items-center">
      <span class="fa fa-spinner fa-spin text-3xl text-primary"></span>
    </div>

    <div v-if="userNeedSign && !showSpinner" :class="isBusinessPartnerAccess ? 'container' : ''">
      <div class="relative mt-6">
        <div class="content">
          <a
            v-if="!isBusinessPartnerAccess"
            href="#"
            @click.prevent="$router.push('/tomador/painel')"
            class="self-start text-sm text-gray-800 underline"
          >Voltar</a>
          <p class="title">Aceite de condições</p>
          <div class="row">
            <p class="text">
              Olá
              <span class="font-bold">{{statusCadastro.nomeSocioLogado}}</span>, o comitê de crédito da Tutu Digital aprovou as seguintes condições para sua empresa:
            </p>
          </div>
          <div class="row">
            <div class>
              <p class="my-2 lg:my-1">
                <span class="font-bold">Valor liberado:</span>
                <span> {{solicitation.valorLiberado | money}}</span>
              </p>
              <p class="my-2 lg:my-1">
                <span class="font-bold">Prazo:</span>
                <span> {{solicitation.prazo}} meses</span>
              </p>
              <p class="my-2 lg:my-1">
                <span class="font-bold">Taxa:</span>
                <span> {{(solicitation.taxa).toFixed(2)}}% a.m.</span>
              </p>
              <p class="my-2 lg:my-1">
                <span class="font-bold">Parcela:</span>
                <span> {{solicitation.parcela | money}}</span>
              </p>
            </div>
            <div class="lg:ml-6">
              <p class="my-2 lg:my-1">
                <span class="font-bold">I.O.F.:</span>
                <span> {{solicitation.iof | money}}</span>
              </p>
              <p class="my-2 lg:my-1">
                <span class="font-bold">T.A.C.:</span>
                <span> {{solicitation.tac | money}}</span>
              </p>
              <p class="my-2 lg:my-1">
                <span class="font-bold">Total de crédito:</span>
                <span> {{solicitation.valorFinanciado | money}}</span>
              </p>
              <p class="my-2 lg:my-1">
                <span class="font-bold">C.E.T.:</span>
                <span> {{(solicitation.cetMensal).toFixed(2)}}% a.m. {{(solicitation.cetAnual).toFixed(2)}}% a.a.</span>
              </p>
            </div>
          </div>
          <div class="row">
            <p class="italic text-sm text-blue-500">* Valores sujeitos à pequenas alterações</p>
          </div>
          <hr class="h-px bg-gray-600 opacity-50 my-2" />
          <div v-if="allowedLocation">
            <div class="row">
              <p
                class="warning warning_blue"
              >Para continuar com o processo de empréstimo é necessário confirmar seus dados pessoais e dar um parecer a respeito das condições aprovadas pelo Comitê de Crédito da Tutu Digital. Caso aceite as condições, a empresa estará apta a participar da nossa próxima rodada de investimentos.</p>
            </div>
            <p class="form_title">DADOS PESSOAIS</p>
            <div class="row">
              <v-input
                class="w-full lg:w-2/4 lg:pr-6"
                name="nomeCompleto"
                v-model="condicoes.nomeCompleto"
                :error="errors.first('nomeCompleto')"
                label="Nome Completo*"
                v-validate="'required|min:2'"
              ></v-input>
              <v-input
                class="w-full lg:w-1/4 lg:pr-6"
                name="cpf"
                v-model="condicoes.cpf"
                :error="errors.first('cpf')"
                label="CPF*"
                mask="###.###.###-##"
                v-validate="'required|cpf'"
              ></v-input>
              <v-input
                class="w-full lg:w-1/4"
                name="rg"
                v-model="condicoes.rg"
                :error="errors.first('rg')"
                label="RG*"
                v-validate="'required|min:2'"
              ></v-input>
            </div>
            <div class="row">
              <v-input
                class="w-full lg:w-1/4 lg:pr-6"
                name="dataNascimento"
                v-model="condicoes.dataNascimento"
                :error="errors.first('dataNascimento')"
                mask="##/##/####"
                :masked="true"
                label="Data de Nascimento*"
                v-validate="'required|date_format:dd/MM/yyyy'"
              ></v-input>
              <v-input
                class="w-full lg:w-2/4 lg:pr-6"
                name="nomeMae"
                v-model="condicoes.nomeMae"
                :error="errors.first('nomeMae')"
                label="Nome Mãe*"
                v-validate="'required|min:2'"
              ></v-input>
              <v-input
                class="w-full lg:w-1/4"
                name="cargo"
                v-model="condicoes.relacaoEmpresa"
                :error="errors.first('cargo')"
                label="Cargo*"
                v-validate="'required|min:2'"
              ></v-input>
            </div>
            <div class="row">
              <v-input
                class="w-full lg:w-1/4 lg:pr-6"
                mask="(##) #####-####"
                :masked="true"
                name="numeroTelefone"
                v-model="condicoes.numeroTelefone"
                :error="errors.first('numeroTelefone')"
                label="Celular*"
                v-validate="'required|min:14|max:15'"
                :disabled="dontAllowChangeTel"
              ></v-input>
              <div class="w-full lg:w-1/4 lg:pr-6 ">
              <button
                  class="btn btn-secondary"
                  :disabled="disabledButton"
                  @click="disabledButton ? () => '' : askForCode()"
                >{{disabledButton ? labelButton : 'Solicitar código por SMS'}}</button>
              </div>
              <v-input
                class="w-full lg:w-1/2"
                name="codigoEnviadoPorSMS"
                v-model="condicoes.codigoEnviadoPorSMS"
                :error="errors.first('codigoEnviadoPorSMS')"
                label="Código recebido por SMS*"
                v-validate="'required|min:2'"
              ></v-input>
            </div>
            <div class="row"></div>
            <p class="form_title">CONTA BANCÁRIA</p>
            <div class="row">
              <v-input
                :options="banks"
                itemValue="instituicaoFerbranId"
                :itemText="['codigo', 'nome']"
                class="w-full lg:w-6/12 lg:pr-6"
                name="bancoId"
                v-model="condicoes.contaBancaria.bancoId"
                :error="errors.first('bancoId')"
                label="Conta Bancária*"
                v-validate="{required: true, is_not: -1}"
              ></v-input>
              <v-input
                class="w-full lg:w-2/12 lg:pr-6"
                name="agencia"
                v-model="condicoes.contaBancaria.agencia"
                :error="errors.first('agencia')"
                label="Agência*"
                v-validate="'required'"
              ></v-input>
              <v-input
                class="w-full lg:w-2/12 lg:pr-6"
                name="conta"
                v-model="condicoes.contaBancaria.conta"
                :error="errors.first('conta')"
                label="Conta*"
                v-validate="'required'"
              ></v-input>
              <v-input
                :options="accounts"
                itemValue="id"
                :itemText="'descricao'"
                class="w-full lg:w-2/12"
                name="tipoconta"
                v-model="condicoes.contaBancaria.tipoConta"
                :error="errors.first('tipoconta')"
                label="Tipo Conta*"
                v-validate="{required: true, is_not: -1}"
              ></v-input>
              <p class="warning warning_red mt-4 lg:mt-8">
                Atenção: A conta bancária deve estar devidamente ativa e registrada no CNPJ da sua empresa. Insira os dados corretamente, incluindo os digitos. Caso seu empréstimo seja aprovado, esta será a conta utilizada para crédito.
                *Não aceitamos conta de pessoa física, apenas jurídica.
              </p>
            </div>
            <div class="row">
              <v-checkbox
                type="checkbox"
                class="text-sm"
                name="termos"
                :error="errors.first('termos')"
                v-model="condicoes.termos"
                v-validate="'required:true'"
              >Declaro que todas as informações acima são verdadeiras e tenho poderes para aprovar a operação de empréstimo para a empresa {{statusCadastro.nomeFantasia}}, CNPJ {{statusCadastro.cnpj}}, sediada em {{statusCadastro.endereco.logradouro}}, {{statusCadastro.endereco.numero}}, {{statusCadastro.endereco.bairro}}, {{statusCadastro.endereco.cidade}} - {{statusCadastro.endereco.uf}}, Brasil.</v-checkbox>
            </div>
            <div class="row">
              <div class="flex flex-col text-sm">
                <v-radio class="inline" name="aprovado" v-model="condicoes.aprovado" label="Aceito as condiçẽs de empréstimo aprovadas pelo Comitê de Crédito e autorizo a inclusão da empresa em uma rodada de investimentos no Painel do investidor da TuTu Digital." :value="true"></v-radio>
                <v-radio class="inline text-red-500" name="aprovado" v-model="condicoes.aprovado" label="Não aceito as condições de empréstimo aprovadas pelo Comitê de Crédito." :value="false"></v-radio>
                <p class="text-xs text-red-600">{{errors.first("aprovado")}}</p>
              </div>
               <v-input
               v-if="!condicoes.aprovado && condicoes.aprovado !== null"
               type="textarea"
               class="w-full"
               name="observacoes"
               v-model="condicoes.observacoes"
               v-validate="'required:true'"
               :error="errors.first('observacoes')"
               label="Pode nos contar porque não aceitou as condições?"></v-input>
            </div>
 <div class="row">
        <v-checkbox @click="!termosuso ? modalTermsBusinessInvestor = true : modalTermsBusinessInvestor = false" type="checkbox" class="text-sm" name="termosuso" :error="errors.first('termosuso')" v-model="termosuso" v-validate="'required:true'">
                <span> Declaro que li e estou de acordo com os Termos e Condições para Tomadores e Investidores</span>
              </v-checkbox>
                  </div>
            <div class="row">
              <v-button
                :disabled="!condicoes.aprovado"
                :title="!condicoes.aprovado ? 'Para continuar é necessário aceitar as condições de empréstimo aprovadas' : ''"
                :loading="loading"
                @click="submit"
                class="w-full lg:w-1/2 lg:pr-2 flex justify-center lg:justify-end"
              >Aceitar</v-button>
              <v-button
                :disabled="condicoes.aprovado || condicoes.aprovado === null"
                :title="condicoes.aprovado || condicoes.aprovado === null ? 'Para continuar é necessário não aceitar as condições de empréstimo aprovadas' : ''"
                :loading="loading"
                 @click="submit"
                class="w-full lg:w-1/2 lg:pl-2 flex justify-center lg:justify-start"
                color="error"
              >Recusar</v-button>
            </div>
          </div>
          <div v-else-if="!allowedLocation && !handlePermissionButton" class="row">
            <p class="italic text-sm text-blue-500">Por questões de segurança, precisamos que você permita o acesso à localização do seu dispositivo clicando no botão abaixo e permitindo o acesso a localização no seu navegador</p>
            <button class="btn btn-primary" @click="getLocation">Solicitar permissão de localização</button>
          </div>
          <div v-else class="row justify-center">
            <p v-if="!locationMessageError" class="italic text-lg text-blue-500">Aguardando permissão de localização</p>
            <p v-else class="italic text-lg text-red-500">{{locationMessageError}}</p>
          </div>
        </div>
      </div>
      <Footer v-if="isBusinessPartnerAccess" class="mt-auto" :dashboard="true" />
    </div>
    <div v-if="!userNeedSign && !showSpinner" :class="isBusinessPartnerAccess ? 'container' : ''">
      <div class="relative mt-6">
        <div class="content">
            <a
              v-if="!isBusinessPartnerAccess"
              href="#"
              @click.prevent="$router.push('/tomador/painel')"
              class="self-start text-sm text-gray-800 underline"
            >Voltar</a>
            <div class="row justify-center">
              <p class="italic text-lg text-grey-700">Você não possuí nenhuma condição aprovada para assinatura.</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Business/NavBar/NavBar'
import Footer from '@/components/Footer/Footer'
import VRadio from '@/components/Inputs/Radio'
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VButton from '@/components/Buttons/Button'
import TermsBusinessInvestorModal from '@/components/Modal/TermsBusinessInvestorModal'

import Forms from '@/services/Business/Forms'
import ApprovedCondition from '@/services/Commons/ApprovedCondition'
import Dashboard from '@/services/Business/Dashboard'
import MoneyFilter from '@/mixins/moneyFilter'
import { setTimeout } from 'timers'

export default {
  name: 'AcceptConditions',
  components: { NavBar, Footer, VInput, VRadio, VButton, VCheckbox,TermsBusinessInvestorModal },
  props: ['id', 'isBusinessPartnerAccess'],
  mixins: [MoneyFilter],
  data () {
    return {
      modalTermsBusinessInvestor: false,
      condicoes: {
        contaBancaria: {
          tipoConta: -1,
          conta: '',
          digitoConta: '',
          agencia: '',
          digitoAgencia: '',
          bancoId: -1
        },
        nomeCompleto: '',
        nomeMae: '',
        cpf: '',
        rg: '',
        numeroTelefone: '',
        dataNascimento: '',
        relacaoEmpresa: '',
        codigoEnviadoPorSMS: '',
        observacoes: '',
        localizacaoBrowser: {
          latitude: '',
          longitude: ''
        },
        aprovado: null,
        termos: false
      },

      solicitation: {
        id: 0,
        pedidoEmprestimoId: 0,
        valorFinanciado: 0,
        valorLiberado: 0,
        iof: 0,
        tac: 0,
        prazo: 0,
        diasCarencia: 0,
        diasAcrescimo: 0,
        cetMensal: 0,
        cetAnual: 0,
        taxa: 0,
        percTAC: 0,
        parcela: 0
      },

      banks: [],
      accounts: [],

      loading: false,
      showSpinner: true,
      sentCode: false,

      allowedLocation: false,
      handlePermissionButton: false,
      locationMessageError: '',

      disabledButton: false,
      labelButton: `Aguarde 60 segundos...`,
      timeButton: 60,

      userNeedSign: false,
      statusCadastro: {},
termosuso: false,
      dontAllowChangeTel : false,
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            await ApprovedCondition.signCondition(this.condicoes)
            if (this.condicoes.aprovado) {
              this.$store.dispatch('notification', { type: 'success', message: 'Condições aceitas com sucesso!' })
            } else {
              this.$store.dispatch('notification', { type: 'success', message: 'Condições recusadas com sucesso!' })
            }
            if (this.$route.path === '/tomador/aceitar-condicoes') {
              this.$router.push('/tomador')
            }
            this.userNeedSign = false
          } catch (error) {
              let erros = "";
              for(var e in error.response.data.erros) {
                erros += error.response.data.erros[e]+ ' ';
              }

          this.$store.dispatch('notification', { type: 'error', message: erros })
          } finally {
            this.loading = false
          }
        }
      }
    },
    async getLastConditionApproved () {
      try {
        const response = (await ApprovedCondition.getLastConditionApproved())
          .data.data
        this.solicitation = response
      } catch (error) {
        console.error(error)
      }
    },
    async askForCode () {
      if (await this.$validator.validate('numeroTelefone', this.condicoes.numeroTelefone)) {
        try {
          this.disabledButton = true
          this.sentCode = true
          const interval = setInterval(() => {
            this.timeButton -= 1
            this.labelButton = `Aguarde ${this.timeButton} segundos...`
          }, 1000)

          const data = {
            telefone: this.condicoes.numeroTelefone,
            localizacaoBrowser: {
              latitude: this.condicoes.localizacaoBrowser.latitude,
              longitude: this.condicoes.localizacaoBrowser.longitude
            }
          }
          const response = (await ApprovedCondition.getTokenSMS(data)).data.data

          setTimeout(() => {
            this.disabledButton = false
            this.timeButton = 60
            this.labelButton = `Aguarde ${this.timeButton} segundos...`
            clearInterval(interval)
          }, 60 * 1000)

        } catch (error) {

          let erros = "";
          for(var e in error.response.data.erros) {
            erros += error.response.data.erros[e]+ ' ';
          }

          this.$store.dispatch('notification', { type: 'error', message: erros })
        }
      }
    },
    getLocation () {
      this.handlePermissionButton = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, () => {
          this.locationMessageError = 'Acidentalmente você acabou bloqueando a permissão de acesso a sua localização, por favor verifique suas configurações e permita o acesso!'
        })
      } else {
        this.locationMessageError = 'Seu navegador não suporta o acesso a sua localização, por favor tente em outro navegador'
      }
    },
    showPosition (position) {
      this.condicoes.localizacaoBrowser.latitude = position.coords.latitude
      this.condicoes.localizacaoBrowser.longitude = position.coords.longitude
      if (position) {
        this.allowedLocation = true
      }
    }
  },
  async mounted () {
    try {
      const response = (await ApprovedCondition.userNeedSign()).data.data
      response ? this.userNeedSign = true : this.userNeedSign = false
      this.showSpinner = false
      if (!this.userNeedSign) {
        return
      }
    } catch (error) {
      console.log(error)
    }
    try{
      this.condicoes.numeroTelefone = (await ApprovedCondition.getTelPartner()).data.data;
    }catch(error){
      console.error(error)
    };

     if(this.condicoes.numeroTelefone){
        this.dontAllowChangeTel = true;
      }
    try {
      this.statusCadastro = (await Dashboard.getStatus()).data.data
    } catch (error) {
      console.error(error)
    }
    try {
      this.banks = (await Forms.getBanks()).data.data
    } catch (error) {
      console.error(error)
    }
    try {
      this.accounts = (await Forms.getEnums()).data.data.tipoConta
    } catch (error) {
      console.error(error)
    }
    try {
      const response = (await ApprovedCondition.getBankAccountPayment()).data.data
      this.condicoes.contaBancaria.bancoId = response.bancoId
      this.condicoes.contaBancaria.conta = response.conta
      this.condicoes.contaBancaria.agencia = response.agencia
      this.condicoes.contaBancaria.tipoConta = response.tipoConta
    } catch (error) {
      console.error(error)
    }
    this.getLastConditionApproved()
    // this.getLocation()
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply w-full min-h-screen h-full
  background: #F5F5F5

.content
  @apply w-full flex flex-col items-center bg-white p-6 border shadow rounded-lg
  @screen lg
    @apply items-start

.row
  @apply block flex flex-col my-2 w-full
  @screen lg
    @apply flex flex-row flex-wrap

.title
  @apply font-bold text-xl text-primary self-center my-6
  @screen lg
    @apply text-2xl

.form_title
  @apply font-bold text-xl text-black mt-6

.text
  @apply
  @screen lg
    @apply text-lg

.warning
  @apply text-sm border shadow-md rounded-lg p-2
  &_red
    @apply text-red-600 border-red-600
  &_blue
    @apply text-blue-600 border-blue-600

.radio
  @apply mr-1 my-auto

.label
  @apply text-sm opacity-75 cursor-pointer mr-2

.btn
  @apply rounded-full my-4 text-white h-10 mx-auto text-sm leading-tight outline-none px-4 w-full
  &[disabled]
    @apply bg-gray-600 cursor-not-allowed opacity-50
  &-primary
    @apply bg-primary w-auto
  &-secondary
    @apply bg-secondary
</style>
